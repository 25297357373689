import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Divider } from 'base-ui'

import { type FooterTenantLink } from '../../../model/footer.types'

import { FooterShops } from './FooterShops'

type FooterCopyrightAndShopsProps = {
  tenantItemsLinks: FooterTenantLink[]
}

export const FooterCopyrightAndShops: FC<FooterCopyrightAndShopsProps> = ({
  tenantItemsLinks,
}) => (
  <div className="container flex flex-col gap-3 py-5 tablet:flex-row tablet:flex-wrap tablet:justify-between">
    <Divider className="tablet:hidden" />
    <div className="text-center text-s tablet:whitespace-nowrap tablet:text-start">
      {`© 2001 - ${new Date().getFullYear()} `}
      <FormattedMessage id="footer.label.legal" />
    </div>
    <FooterShops tenantLinksItems={tenantItemsLinks} />
  </div>
)
