import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { useActivateContentsquareExperiment } from 'contentsquare'

import { Experiment } from '../../../../experiments/components/Experiment'
import { ExperimentVariant } from '../../../../experiments/components/ExperimentVariant'
import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
  experimentsTrackEvent,
} from '../../../../experiments/eventTracking'
import { HeaderMobileIconListExperiment } from '../../../../experiments/header-mobile-icon-list-experiment/HeaderMobileIconListExperiment'
import { HeaderMobileIconListExperimentLogo } from '../../../../experiments/header-mobile-icon-list-experiment/HeaderMobileIconListExperimentLogo'
import { useGetExperiment } from '../../../../experiments/hooks'
import { EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST } from '../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../experiments/model/EXPERIMENT_VARIATION'
import { useGlobalConfigContext } from '../../../../global-config'
import { urlResolverGetHomepage } from '../../../../url-handling'
import { HeaderSearchActiveSearchBox } from '../../header-search/components/HeaderSearchActiveSearchBox/HeaderSearchActiveSearchBox'
import { useHeaderSearchContext } from '../../header-search/headerSearchContext'
import { useHeaderDataContext } from '../../headerConfigContext'
import { headerCmsBlockMenuAccessorGetHamburgerMenu } from '../../model/headerCmsBlockMenuAccessor'
import { OffCanvasHeaderMenuContainer } from '../../off-canvas-header-menu/components/OffCanvasHeaderMenuContainer'

import { HeaderMobileIconList } from './HeaderMobileIconList'

import styles from './HeaderMobile.module.css'

export const HeaderMobile: FC = () => {
  const headerData = useHeaderDataContext()
  const headerSearchContext = useHeaderSearchContext()
  const publicConfig = useGlobalConfigContext()

  const logo = headerData.shopLogos.mobile
  useActivateContentsquareExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  const { variant } = useGetExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  return (
    <>
      <header
        className={clsx(
          styles['header-mobile'],
          'container flex items-center justify-between border-b border-b-dark-primary-low bg-light-primary-low pt-0.5',
        )}
      >
        <OffCanvasHeaderMenuContainer
          hamburgerMenuData={headerCmsBlockMenuAccessorGetHamburgerMenu(
            headerData.cmsMenu,
          )}
        />
        <div>
          <a
            href={urlResolverGetHomepage(publicConfig)}
            onClick={() => {
              experimentsTrackEvent(
                publicConfig,
                EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_LOGO,
              )
            }}
          >
            {variant === EXPERIMENT_VARIATION.V1 ? (
              <HeaderMobileIconListExperimentLogo />
            ) : (
              <Image alt="logo" src={logo} />
            )}
          </a>
        </div>
        <Experiment names={[EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST]}>
          <ExperimentVariant name={EXPERIMENT_VARIATION.DEFAULT}>
            <HeaderMobileIconList />
          </ExperimentVariant>
          <ExperimentVariant name={EXPERIMENT_VARIATION.V1}>
            <HeaderMobileIconListExperiment />
          </ExperimentVariant>
        </Experiment>
      </header>
      {headerSearchContext.searchIsActive ? (
        <HeaderSearchActiveSearchBox
          /*
           * On `desktop`, search box is always a portal to `HeaderSearchDesktop`,
           * on mobile it is rendered without portal here.
           */
          className={clsx(
            !headerSearchContext.portalResultsTo && 'tablet:hidden',
          )}
        />
      ) : null}
    </>
  )
}
