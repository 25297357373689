import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import {
  type assetsCountryFlagLogoMap,
  assetUrlGetCountryFlag,
} from '../../../../../../url-handling'

import { type FooterShopsProps } from './FooterShopsProps'

export const FooterShops: FC<FooterShopsProps> = ({ tenantLinksItems }) => {
  const { assetsPrefix } = useGlobalConfigContext()

  return (
    <div className="flex items-center gap-3">
      <span className="text-s">
        <FormattedMessage id="footer.label.country" />
        {': '}
      </span>
      <ul className="flex gap-3">
        {tenantLinksItems.map(({ alt, href, id }) => (
          <li key={id}>
            <a href={href}>
              <NextImageWithFallback
                alt={alt}
                fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
                height={20}
                src={assetUrlGetCountryFlag({
                  assetsPrefix,
                  country: id as keyof typeof assetsCountryFlagLogoMap,
                })}
                width={30}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
